import React from 'react';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../components/Colors';
import { faPray } from '@fortawesome/free-solid-svg-icons';
import { MDXRenderer } from 'gatsby-plugin-mdx';

// Each page will use <> wrapper to get global styles.
export default ({ data: { mdx: fragment } }) => {
  return (
    <>
      <Layout
        jumbo={true}
        pageTitle="Christian Bytes Content Calendar & Log"
        pageDescription="A log showing our upcoming content as well as past releases."
      >
        <h1
          css={css`
            color: ${Colors.main} !important;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 2rem;
            border-bottom: 2px solid ${Colors.main};
          `}
        >
          <FontAwesomeIcon
            css={css`
              color: ${Colors.main} !important;
              margin-right: 0.5rem;
            `}
            icon={faPray}
            size={'sm'}
            width="28"
          />
          Christian Bytes Content Calendar & Log
        </h1>
        <MDXRenderer>{fragment.body}</MDXRenderer>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    mdx(frontmatter: { slug: { eq: "fragment-content-calendar" } }) {
      id
      body
      frontmatter {
        slug
      }
    }
  }
`;
